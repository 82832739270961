export default function(tableSelector:string, toggleRowSelector:string, callback?:Function){
    const tableRows:Array<HTMLTableRowElement> = Array.from(document.querySelectorAll(tableSelector + " " + toggleRowSelector));

    const groupOfRows = (elm:HTMLTableRowElement, res:Array<HTMLTableRowElement>):Array<HTMLTableRowElement> => {
        const result = [...res];
        if(elm.nextElementSibling && elm.nextElementSibling.getAttribute("data-jscomponent") !== toggleRowSelector.match(/\'(.*)\'/)[1]){
            const nextElmRow = <HTMLTableRowElement>elm.nextElementSibling;
            result.push(nextElmRow);
            return groupOfRows(nextElmRow, result);
        }

        return result;
    };

    const toggle = (row:HTMLTableRowElement) => {
        row.classList.toggle("skjema-admin__toggle-row-expanded");
        row.classList.toggle("d-none");
    };

    tableRows.forEach(row => {
        row.addEventListener("click", event => {
            const currentRow = <HTMLTableRowElement>event.currentTarget;
            const rows = groupOfRows((currentRow as HTMLTableRowElement),[]);

            const iconElm = currentRow.querySelector(".budicon");

            const chevronType = (() => {
                if(iconElm.classList.contains("budicon-chevron-bottom")){
                    return "budicon-chevron-bottom";
                }
                return "budicon-chevron-right";
            })();

            const isClosed = iconElm.classList.contains(chevronType);

            if(isClosed){
                iconElm.classList.remove(chevronType);
                iconElm.classList.add("budicon-chevron-top");
            }else{
                iconElm.classList.remove("budicon-chevron-top");
                iconElm.classList.add(chevronType);
            }

            rows.forEach(toggle);
            row.classList.toggle("skjema-admin__toggle-row-expanded")

            if(callback){
                callback();
            }
        });
    });
}