export const sortTableRow = function (
    trSelector: string,
    tdSelector: string,
    tableElement: HTMLTableElement,
    isReverse: boolean,
    isDate: boolean
) {
    const createDate = function (timeStr: string) {
        const [day, month, year] = timeStr.split(".");
        return new Date(parseInt(year), parseInt(month), parseInt(day));
    };

    const listOfTr = Array.from(tableElement.querySelectorAll(trSelector)).map(
        (tr) => tr
    );
    const tempFragment = document.createDocumentFragment();

    listOfTr.sort((a: HTMLTableRowElement, b: HTMLTableRowElement) => {
        const aInnerText = (a.querySelector(tdSelector) as HTMLTableCellElement)
            .innerText;
        const bInnerText = (b.querySelector(tdSelector) as HTMLTableCellElement)
            .innerText;

        if (isDate) {
            const aDate = createDate(aInnerText);
            const bDate = createDate(bInnerText);

            if (aDate.getTime() > bDate.getTime()) {
                return -1;
            } else {
                return 1;
            }
        } else {
            const firstLetterOfA = aInnerText.toLowerCase().slice(0, 1);
            const firstLetterOfB = bInnerText.toLowerCase().slice(0, 1);

            return firstLetterOfA.localeCompare(firstLetterOfB);
        }
    });

    if (isReverse) {
        listOfTr.reverse();
    }

    listOfTr.forEach((a) => tempFragment.appendChild(a));

    return tempFragment;
};

export const filterTableFragment = (
    table: HTMLTableElement,
    filterStr: string
) => {
    const cloneTable = table.cloneNode(true);
    const tbody = (cloneTable as HTMLTableElement).querySelector("tbody");
    const frag = document.createDocumentFragment();

    const filteredTbody = Array.from(tbody.querySelectorAll("tr")).filter(
        (tr) => {
            const result = Array.from(
                tr.querySelectorAll("td:nth-child(1),td:nth-child(3)")
            ).filter((td) => {
                return (
                    (td as HTMLTableCellElement).innerText
                        .toLowerCase()
                        .indexOf(filterStr.toLowerCase()) !== -1
                );
            });

            return result.length > 0;
        }
    );

    filteredTbody.forEach((tr) => frag.appendChild(tr));

    return frag;
};

export const renderVersionsTableBody = (templateId: null, versionData: IVersionData[]) => {
    const frag = document.createDocumentFragment();

    const latestVersionFirst = (a: IVersionData, b: IVersionData) => {
        if (a.version > b.version) {
            return -1;
        } else if (a.version < b.version) {
            return 1;
        }
        return 0;
    }

    versionData.sort(latestVersionFirst);
    versionData.forEach((dataItem) => {
        const tr = document.createElement("tr");

        tr.innerHTML = `
            <td>
                ${dataItem.version}
            </td>
            
            <td>
                ${dataItem.lastChanged}
            </td>

            <td>
                ${dataItem.lastSavedByAdminUserId}
            </td>

            <td>
                ${dataItem.activatedByAdminUserId}
            </td>

            <td>
                ${dataItem.comment}
            </td>
            
            <td>
                <button class="skjema-admin__designer__button skjema-admin__designer__button--primary" type="button" data-jsid="getFormVersionButton" data-version="${dataItem.version}" data-templateId="${templateId}" >
                    Hent versjon
                </button>
            </td>
        `;

        frag.appendChild(tr);
    });

    return frag;
};

const throwError = async (
    response: Response,
    errorCallback: (
        status: number,
        statusText: string,
        responseText: string
    ) => void
) => {
    if (!response.ok) {
        const responseText = await response.text();
        errorCallback(response.status, response.statusText, responseText);
        // const message = `An error has occured: ${response.status} ${response.statusText}`;
        const message = response.statusText;
        console.error(message);
        //throw new Error(message);
    }
};

export const categoriesApiCall = async (
    apiUrl: string,
    successCallback: (data: Promise<string>) => void
) => {
    try {
        const response = await fetch(apiUrl + "/categories", {
            method: "get",
            credentials: "include",
        });

        if (!response.ok) {
            throw Error(
                `Response failed ${response.status} ${response.statusText}`
            );
        }

        const jsonData = await response.json();

        successCallback(JSON.parse(jsonData));
    } catch (e) {
        throw Error(e);
    }
};

const getTemplateId = (apiUrl: string, customerId: string, formId: string) => {
    const getTemplateUrl = `${apiUrl}/${customerId}/designer/template?formid=${formId}`;
    return fetch(getTemplateUrl, {
        method: "get",
        credentials: "include",
    });
};

export const getFormVersions = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: (data: IVersionData[]) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    const getVersionUrl = `${apiUrl}/${customerId}/designer/getversions?templateId=${templateId}`;

    const versionResponse = await fetch(getVersionUrl, {
        method: "get",
        credentials: "include",
    });

    await throwError(versionResponse, errorCallback);

    const versionDataArray: IVersionData[] = await versionResponse.json();
    successCallback(versionDataArray);
};

export const shareForm = async (apiUrl: string, customerId: string, formId: string, isShared: boolean,
    successCallback: () => void,
    errorCallback: (status: number, statusText: string) => void) => {
    
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;
    
    if (templateId) {
        const shareFormUrl = `${apiUrl}/${customerId}/designer/setSharedStatus?templateId=${templateId}&isShared=${isShared}`;
        const shareResponse = await fetch(shareFormUrl, {
            method: "put",
            credentials: "include",
        });

        await throwError(shareResponse, errorCallback);
        successCallback();
    }
};

export const createDesignerForm = async (
    apiUrl: string,
    formId: string,
    title: string,
    categoryId: string,
    securityLevelId: string,
    errorCallback: (status: number, statusText: string) => void,
    successCallback: (createFormData: ICreateFormData) => void
) => {
    const templateUrl = `${apiUrl}/createTemplate?formid=${formId}&name=${title}&category=${categoryId}&securitylevel=${securityLevelId}`;
    const response = await fetch(templateUrl, {
        method: "post",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    });

    await throwError(response, errorCallback);
    const jsonResponse = await response.json();

    successCallback(JSON.parse(jsonResponse));
};

export const deleteDesignerFormVersion = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: () => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    if (templateId) {
        const deleteTemplateUrl = `${apiUrl}/${customerId}/designer/deletetemplate?templateid=${templateId}`;

        const deleteResponse = await fetch(deleteTemplateUrl, {
            method: "post",
            credentials: "include",
        });

        await throwError(deleteResponse, errorCallback);
        successCallback();
    }
};

export const copyDesignerForm = async (
    apiUrl: string,
    customerId: string,
    oldFormId: string,
    formId: string,
    formTitle: string,
    category: string,
    securityLevel: string,
    successCallback: (templateId: string) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, oldFormId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    const copyUrl = `${apiUrl}/${customerId}/designer/copyTemplate?existingTemplateId=${templateId}&newFormid=${formId}&newName=${formTitle}&category=${category}&securityLevel=${securityLevel}`;

    const copyResponse = await fetch(copyUrl, {
        method: "post",
        credentials: "include",
    });

    await throwError(copyResponse, errorCallback);
    const copyData = await copyResponse.json();
    const parsedCopyData = JSON.parse(copyData);
    successCallback(parsedCopyData.eDocumentTemplateID);
};

export const  moveDesignerForm = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    category: string,
    securityLevel: string,
    successCallback: () => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    const moveUrl = `${apiUrl}/${customerId}/designer/movetemplatefromcatalog?templateId=${templateId}&category=${category}&securityLevel=${securityLevel}`;

    const moveResponse = await fetch(moveUrl, {
        method: "post",
        credentials: "include",
    });

    await throwError(moveResponse, errorCallback);
    //const moveData = await moveResponse.json();
    //const parsedCopyData = JSON.parse(moveData);
    successCallback();
};

export const unlockDesignerForm = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: (templateId: string) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    let templateVersionID = parsedTemplateData?.templateVersionID;
    if(templateVersionID == "00000000-0000-0000-0000-000000000000") {
        templateVersionID = parsedTemplateData?.currentVersionId
    }
    const unlockyUrl = `${apiUrl}/${customerId}/designer/stopedit?customerId={customerId}&templateVersionId=${templateVersionID}`;

    const unlockResponse = await fetch(unlockyUrl, {
        method: "post",
        credentials: "include",
    });

    await throwError(unlockResponse, errorCallback);
    //const copyData = await unlockResponse.json();
    //const parsedCopyData = JSON.parse(copyData);
    successCallback(templateVersionID);
};

export const adminAccess = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    setLock: boolean,
    successCallback: () => void,
    errorCallback: (status: number, statusText: string) => void
) => {

    const setLockBySuperAdminUrl = `${apiUrl}/${customerId}/designer/SetLockBySuperAdmin?FormId=${formId}&SetLock=${setLock}`;

    const LockBySuperAdminResponse = await fetch(setLockBySuperAdminUrl, {
        method: "post",
        credentials: "include",
    });

    await throwError(LockBySuperAdminResponse, errorCallback);
    //const LockBySuperAdminResponseData = await LockBySuperAdminResponse.json();
    //const parsedLockBySuperAdminResponseData = JSON.parse(LockBySuperAdminResponseData);

    successCallback(); //parsedLockBySuperAdminResponseData);
}
export const isFormInEditMode = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: (data: IIsFomInEditModeData) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateVersionId = parsedTemplateData?.currentVersionId;

    const ifEditUrl = `${apiUrl}/${customerId}/designer/ifEdit?templateVersionId=${templateVersionId}`;

    const ifEditResponse = await fetch(ifEditUrl, {
        method: "get",
        credentials: "include",
    });

    await throwError(ifEditResponse, errorCallback);
    const ifEditData = await ifEditResponse.json();
    const parsedIfEditData = JSON.parse(ifEditData);

    successCallback(parsedIfEditData);
};

export const checkIfFormIsConvertible = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: (convertFormData: IConvertCheckData) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    // await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    const convertUrl = `${apiUrl}/${customerId}/designer/canConvertBinaryToJson?templateId=${templateId}`;
    try {
        const convertResponse = await fetch(convertUrl, {
            method: "get",
            credentials: "include",
        });
        const convertData = await convertResponse.json();
        //const parsedConvertData = JSON.parse(convertData);
        successCallback(convertData);
    }
    catch(error) {
        console.log(error);
    }

    // await throwError(convertResponse, errorCallback);
    
};

export const convertDesignerForm = async (
    apiUrl: string,
    customerId: string,
    formId: string,
    successCallback: (convertFormData: IConvertFormData) => void,
    errorCallback: (status: number, statusText: string) => void
) => {
    const templateResponse = await getTemplateId(apiUrl, customerId, formId);
    // await throwError(templateResponse, errorCallback);

    const templateData = await templateResponse.json();
    const parsedTemplateData = JSON.parse(templateData);
    const templateId = parsedTemplateData?.eDocumentTemplateID;

    const convertUrl = `${apiUrl}/${customerId}/designer/convertBinaryToJson?templateId=${templateId}`;
    try {
        const convertResponse = await fetch(convertUrl, {
            method: "get",
            credentials: "include",
        });
        const convertData = await convertResponse.json();
        //const parsedConvertData = JSON.parse(convertData);
        successCallback(convertData);
    }
    catch(error) {
        console.log(error);
    }

    // await throwError(convertResponse, errorCallback);
    
};

interface IVersionData {
    comment: string;
    eDocumentTemplateVersionID: string;
    lastChanged: string;
    version: number;
    lastSavedByAdminUserId: string;
    activatedByAdminUserId: string;
}

interface ICreateFormData {
    eDocumentTemplateID: string;
    eDocumentTemplateVersionID: string;
}

interface IIsFomInEditModeData {
    id: string | null;
    displayName: string | null;
    email: string | null;
}

interface IConvertCheckData {
    isSuccess: string;
    errors: string[];
}

interface IConvertFormData {
    isSuccess: string;
    versionId: string;
    versionNumber: string
}