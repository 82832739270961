import {CLASS, ID} from "./constants";

export const applyLastState = (target:HTMLElement) => {
    const link = target.querySelector(`a[href='${location.pathname}']`);

    if(link){
        const ul = link.closest(`[data-js=${ID.MENU_LIST}]`);

        ul.previousElementSibling.classList.add(CLASS.ACTIVE_TOGGLE);
        link.classList.add(CLASS.ACTIVE_LINK);
    }else{
        const lastVisitedUrl = sessionStorage.getItem(ID.LAST_VISITED);
        const lastVisitedAnchor = target.querySelector(`[href='${lastVisitedUrl}']`);

        if(lastVisitedAnchor){
            const ul = lastVisitedAnchor.closest(`[data-js=${ID.MENU_LIST}]`);

            ul.previousElementSibling.classList.add(CLASS.ACTIVE_TOGGLE);
            lastVisitedAnchor.classList.add(CLASS.ACTIVE_LINK);
        }
    }
};

const removeAnchorState = (target:HTMLElement) => {
    Array.from(target.querySelectorAll(`.${CLASS.ACTIVE_LINK}`))
        .forEach(elm => {
            elm.classList.remove(CLASS.ACTIVE_LINK);
        });
};

export const applyAnchorState = (target:HTMLElement,activeElement:HTMLElement) => {
    removeAnchorState(target);
    activeElement.classList.add(CLASS.ACTIVE_LINK);

};

export const saveLastState = (href:string) =>
    sessionStorage
        .setItem(ID.LAST_VISITED,href);

export const toggleMenu = (element:HTMLElement,className:string) => {
    if(element.classList.contains(className)){
        return element.classList.remove(className);
    }

    return element.classList.add(className);
};

export const expandCollapse = (
    targetElement:HTMLElement,
    sideMenuContainer:HTMLElement,
    mainPageContainer:HTMLElement,
    sideMenuExpandButton:HTMLElement) => {

    if(sideMenuContainer.classList.contains("col-2")){

        const sideRect = sideMenuContainer.getBoundingClientRect();
        const mainRect = mainPageContainer.getBoundingClientRect();

        sideMenuContainer
            .classList
            .remove("col-2","col-sm-4","col-lg-3","col-xl-2");

        mainPageContainer
            .classList
            .remove("col-10", "col-sm-8","col-lg-9", "col-xl-10");

        targetElement.style.display="none";
        sideMenuContainer.style.maxWidth="20px";
        sideMenuContainer.style.width="20px";

        mainPageContainer.style.maxWidth = Math.round(sideRect.width + mainRect.width) + "px";
        mainPageContainer.style.width = Math.round(sideRect.width + mainRect.width - 70) + "px";

        sideMenuExpandButton.classList.add(CLASS.BUTTON_COLLAPSED);
    }else{
        sideMenuContainer
            .classList
            .add("col-2","col-sm-4","col-lg-3","col-xl-2");

        mainPageContainer
            .classList
            .add("col-10", "col-sm-8","col-lg-9", "col-xl-10");

        sideMenuContainer.style.maxWidth="387px";
        sideMenuContainer.style.width="";

        mainPageContainer.style.maxWidth = "";
        mainPageContainer.style.width = "";

        sideMenuExpandButton.classList.remove(CLASS.BUTTON_COLLAPSED);

        setTimeout(() => {
            targetElement.style.display="block";
        },200);
    }
};