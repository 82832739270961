export default function (selector: string,): void {
    const fileUploads: HTMLInputElement[] = Array.from(document.querySelectorAll(selector));

    fileUploads.forEach(widgetElement => {

        const fileUploadElement = widgetElement.querySelector("input[type='file']");
        const fakeButton = widgetElement.querySelector("[data-jsid='fileUploadFakeButton']");
        const imgPreview:HTMLImageElement = widgetElement.querySelector("[data-jsid='fileUploadImgPreview']");
        const fileUploadText = widgetElement.querySelector("[data-jsid='fileUploadText']");

        if(imgPreview && imgPreview.src.length > 0){
            fileUploadText.innerHTML = "";
        }

        fileUploadElement.addEventListener("change", (event) => {
            const target = <HTMLInputElement>event.target;
            const {files} = (<HTMLInputElement>fileUploadElement);

            fileUploadElement
                .parentElement
                .parentElement
                .previousElementSibling
                .innerHTML = files[0].name + " er klar for å bli lastet opp. <br/> Trykk på lagre."
        });

        fileUploadElement.addEventListener("mouseover", () => {
            fakeButton.classList.add("skjema-admin__button--secondary-hover");
        });

        fileUploadElement.addEventListener("mouseleave", () => {
            fakeButton.classList.remove("skjema-admin__button--secondary-hover");
        });
    });
}