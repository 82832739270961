export default function (tableSelector: string, filterSelectors: string, triggerButton: string) {
    const table = document.querySelector<HTMLElement>(tableSelector);
    const tableFilter: HTMLInputElement = document.querySelector(filterSelectors);
    const tableFilterSubmit: HTMLButtonElement = document.querySelector(triggerButton);

    const filter = (inputElement: HTMLInputElement) => {
        const targetValue = inputElement.value;
        const rows = Array.from(table.querySelectorAll("tr"));

        rows.slice(1).forEach((row) => row.classList.add("d-none"));

        const filteredRows =
            targetValue == ""
                ? rows
                : rows.filter((row) => {
                      if (row.parentNode.nodeName == "THEAD") {
                          return true;
                      }
                      let idRow = "";
                      let tds = "";
                      let tableType = table.dataset.jsid;
                      switch (tableType) {
                          case "tableOfForms":
                              idRow = row.querySelectorAll("td")[3].innerHTML;
                              tds = row.querySelectorAll("td")[4]?.querySelector("a").innerHTML;
                              break;
                          case "tableOfSharedForms":
                              idRow = row.querySelectorAll("td")[1].innerHTML;
                              tds = row.querySelectorAll("td")[2].innerHTML;
                              break;
                          case "tableOfFormsDesigner":
                              idRow = row.querySelectorAll("td")[0].innerHTML;
                              tds = row.querySelectorAll("td")[2].innerHTML;
                              break;
                      }

                      if (tds && idRow) {
                          const idFound = idRow.toLowerCase().indexOf(targetValue.toLowerCase()) !== -1;
                          const titleFound = tds.toLowerCase().indexOf(targetValue.toLowerCase()) !== -1;
                          return idFound || titleFound;
                      }
                      return false;
                  });
        if (targetValue == "") {
            //linkClearFilter
            document.getElementById("linkClearFilter").classList.add("d-none");
        } else {
            document.getElementById("linkClearFilter").classList.remove("d-none");
        }
        if (filteredRows.length == 1) {
            document.getElementById("plural").classList.add("d-none");
        } else {
            document.getElementById("plural").classList.remove("d-none");
        }

        filteredRows.forEach((row) => row.classList.remove("d-none"));
        document.getElementById("formsCount").innerText = filteredRows.length.toString();
    };

    if (table && tableFilter) {
        if (tableFilterSubmit) {
            tableFilter.addEventListener("input", (event) => {
                const filterTextLenght = (<HTMLInputElement>event.target).value.length;
                if (filterTextLenght >= 3 || filterTextLenght == 0) {
                    filter(<HTMLInputElement>event.target);
                }
            });
        } else {
            tableFilter.addEventListener("input", (event) => {
                const filterTextLenght = (<HTMLInputElement>event.target).value.length;
                if (filterTextLenght >= 3 || filterTextLenght == 0) {
                    filter(<HTMLInputElement>event.target);
                }
            });
        }
    }
}
