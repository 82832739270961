export default function(tableSelector:string,selector:string):void{
    const tables = Array.from(document.querySelectorAll(tableSelector));

    const tickAll = (isAllChecked:boolean, checkboxes:HTMLInputElement[]) => {
        if(isAllChecked)
            return checkboxes.forEach(elm => {
                elm.setAttribute("checked","checked");
                elm.checked = true;
                elm.closest("tr").classList.add("selected-row");
            });
        return checkboxes.forEach(elm => {
            elm.setAttribute("checked","checked")
            elm.checked = false;
            elm.closest("tr").classList.remove("selected-row");
        });
    };

    const tickToggleCheckbox = (mainCheckbox:HTMLInputElement,checkboxes:HTMLInputElement[]) => {
        const isAllChecked = checkboxes.every(elm => elm.checked);

        if(isAllChecked){
            mainCheckbox.setAttribute("checked", "checked");
            mainCheckbox.checked = true;
        }else{
            mainCheckbox.removeAttribute("checked");
            mainCheckbox.checked = false;
        }
    };

    const addListener = (table:HTMLTableElement) => {
        table.addEventListener("change", (event) => {
            const clickedElement = <HTMLElement>event.target;
            const isCheckbox = clickedElement.nodeName === "INPUT" && clickedElement.getAttribute("type") === "checkbox";

            if(isCheckbox){
                const isToggle = (<HTMLElement>clickedElement.parentNode).classList.contains("jsAllCheck");
                const isSingle = (<HTMLElement>clickedElement.parentNode).classList.contains("jsSingleCheck");
                const isChecked = (<HTMLInputElement>clickedElement).checked;
                const checkboxes:HTMLInputElement[] = Array.from(table.querySelectorAll(".jsSingleCheck input"));

                clickedElement.closest("tr").classList.toggle("selected-row");

                if(isToggle){
                    tickAll(isChecked, checkboxes);
                }else if(isSingle){
                    tickToggleCheckbox(table.querySelector(".jsAllCheck input"),checkboxes);
                }
            }
        });
    };

    tables.forEach(addListener);
}