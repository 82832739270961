enum ContextMenu {
    visibleClass = "skjema-admin__context-menu--visible"
}

export default function(selector:string, closeSelector:string, callback?:Function){
    const toggleButtons = Array.from(document.querySelectorAll(selector));
    const closeButtons = Array.from(document.querySelectorAll(closeSelector));
    let currentContext:HTMLElement, currentToggle:HTMLElement;

    toggleButtons.forEach((button:HTMLButtonElement) => {
        button.addEventListener("click", (event) => {
            const toggleButton = <HTMLButtonElement>event.currentTarget;
            const rect = toggleButton.getBoundingClientRect();
            const contextMenu = <HTMLElement>toggleButton.nextElementSibling;

            if(contextMenu.classList.contains(ContextMenu.visibleClass)){
                contextMenu.classList.remove(ContextMenu.visibleClass);
                button.closest("tr").classList.remove("context-menu-open");
            }else{
                contextMenu.classList.add(ContextMenu.visibleClass);
                const paddingLeft = parseInt(getComputedStyle((<HTMLElement>contextMenu.parentNode)).paddingLeft.replace("px",""));

                contextMenu.style.left = Math.round(-contextMenu.getBoundingClientRect().width + rect.width + paddingLeft) + "px";
                button.closest("tr").classList.add("context-menu-open");
            }

            if(callback){
                currentContext = contextMenu;
                currentToggle = toggleButton;
                callback(contextMenu,toggleButton);
            }
        });
    });

    closeButtons.forEach((button:HTMLButtonElement) => {
        button.addEventListener("click", (event) => {
            (<HTMLButtonElement>button.parentNode).classList.remove(ContextMenu.visibleClass);
            if(callback){
                callback((<HTMLElement>event.currentTarget).parentElement,(<HTMLElement>event.currentTarget).parentElement.previousElementSibling);
            }
        });
    });

    document.body.addEventListener("click", (event) => {
        closeButtons.forEach(button => {
            (<HTMLButtonElement>button.parentNode).classList.remove(ContextMenu.visibleClass);
            if(button.closest("tr")){
                button.closest("tr").classList.remove("context-menu-open");
            }
        });

        if(callback && currentContext && currentToggle){
            callback(currentContext,currentToggle);
        }
    }, true);
}